import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['clusterizationParams', 'nextBtn']

  // Event handler
  nextStepButtonHandler = (event) => this.updateNextButton(event.detail.state)

  connect(){
    document.addEventListener('change-initialization-next-step-button', this.nextStepButtonHandler)
  }

  disconnect(){
    document.removeEventListener('change-initialization-next-step-button', this.nextStepButtonHandler)
  }

  toggleClusterizationParam(){
    if (event.currentTarget.value === 'existing'){
      this.clusterizationParamsTarget.classList.add('hidden')
    } else {
      this.clusterizationParamsTarget.classList.remove('hidden')
    }
  }

  updateNextButton(state){
    if (state === 'enable') {
      this.nextBtnTarget.classList.remove('btn-disabled')
    } else {
      this.nextBtnTarget.classList.add('btn-disabled')
    }
  }
}