import { Controller } from "@hotwired/stimulus"
import { removeContent, projectName } from '../components/utilities';

export default class extends Controller {

  updateRight(){
    const checkbox = event.currentTarget;
    const checked = checkbox.checked;
    const featureId = checkbox.closest('tr').dataset.featureId;
    const right = checkbox.name;

    const url = `${window.location.origin}/${projectName(window.location.pathname)}/admin/features/${featureId}/update_feature_right`;

    const body = { 
      right: right,
      checked: checked
     }

    // AJAX call to update the user role
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data["flash"]);
      const parentContainer = this.element.parentElement;
      removeContent(this.element);
      parentContainer.innerHTML = data["html"];
    })
  }
}
