import { Controller } from "@hotwired/stimulus"
import initializeSelect from "../components/initialize_select";

export default class extends Controller {
  static targets = ["selectLanguage"];

  connect(){
      initializeSelect({
        selector: this.selectLanguageTarget,
        multiple: false,
        addable: false,
        closeAfterSelect: true,
        otherSettings: {
          options: JSON.parse(this.selectLanguageTarget.dataset.options),
          items: this.selectLanguageTarget.dataset.items
        }
      })
  }
}
